@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
    font-family: "Poppins-Regular";
    src: url("../public/assets/fonts/popins/Poppins-Regular.ttf");
}

@font-face {
    font-family: "Poppins-SemiBold";
    src: url("../public/assets/fonts/popins/Poppins-SemiBold.ttf");
}

@font-face {
    font-family: "Poppins-Medium";
    src: url("../public/assets/fonts/popins/Poppins-Medium.ttf");
}

@font-face {
    font-family: "Poppins-Bold";
    src: url("../public/assets/fonts/popins/Poppins-Bold.ttf");
}

@font-face {
    font-family: "WorkSans-Medium";
    src: url("../public/assets/fonts/work_sans/WorkSans-Medium.ttf");
}

@font-face {
    font-family: "WorkSans-Regular";
    src: url("../public/assets/fonts/work_sans/WorkSans-VariableFont_wght.ttf");
}

.banner_bg:after{content:"";position:absolute;background:url(../public/assets/images/gradiant_bg.jpg) no-repeat center center;background-size:cover;opacity:0.2;left:0;right:0;top:0;bottom:0}

@media screen and (min-width: 1024px) {
.sticky_position{position:-webkit-sticky;position:sticky;top:100px}
.sticky_position1{position:-webkit-sticky;position:sticky;top:88px;background:#FFF;padding:25px 0 15px}
}

.blog_detail p.date{font-family: "WorkSans-Regular";font-size:14px;color:#212121;padding:15px 0}
.blog_detail h2{font-family: "Poppins-SemiBold";font-size:32px;color:#212121;padding:0 0 15px}
.blog_detail h3{font-family: "Poppins-SemiBold";font-size:28px;color:#212121;padding:0px}
.blog_detail h4{font-family: "Poppins-SemiBold";font-size:24px;color:#212121;padding:0;margin:0}
.blog_detail p{font-family: "WorkSans-Regular";font-size:18px;color:#212121;padding:0 0 20px;margin:0}
.blog_detail ul{display:block;margin:0;padding:0}
.blog_detail ul li{display:block;margin:0;padding:0 0 0 20px;position:relative;list-style:disc inside none}
.blog_detail ul li:after{content:'\e122';font-family:"Font Awesome 6 Pro";font-weight: 900;position:absolute;left:0;top:8px;font-size:14px;}

@media screen and (max-width:1024px) {
.blog_detail h2{font-size:28px}
.blog_detail h3{font-size:22px}
.blog_detail h4{font-size:20px}
.blog_detail p{font-size:16px}
}

.cms_pages h2{font-family: "Poppins-SemiBold";font-size:32px;color:#212121;padding:0 0 15px}
.cms_pages h3{font-family: "Poppins-SemiBold";font-size:28px;color:#212121;padding:0px}
.cms_pages h4{font-family: "Poppins-SemiBold";font-size:24px;color:#212121;padding:0;margin:0}
.cms_pages p{font-family: "WorkSans-Regular";font-size:18px;color:#212121;padding:0 0 20px;margin:0}

.cms_pages ul{display:block;margin:0;padding:0 0 15px}
.cms_pages ul li{display:block;margin:0;padding:0 0 0 20px;position:relative;list-style:disc inside none}
.cms_pages ul li p{padding:0;margin:0}
.cms_pages ul li:after{content:'\e122';font-family:"Font Awesome 6 Pro";font-weight: 900;position:absolute;left:0;top:2px;font-size:10px;}

.cms_pages ol{display:block;margin:0;padding:0 0 15px}
.cms_pages ol li{display:block;margin:0;padding:3px 0 3px 20px;position:relative;list-style:disc inside none}
.cms_pages ol li ul{list-style:decimal inside none;}
.cms_pages ol li ul li{list-style:decimal inside none;}
.cms_pages ol li ul li p{padding:0;margin:0}
.cms_pages ol li ul li:after{content:'\f0da';top:3px;}
.cms_pages ol li h2{font-family: "Poppins-SemiBold";font-size:24px;color:#212121;padding:0 0 10px;margin:0}
/* .cms_pages ol li:after{content:'\e122';font-family:"Font Awesome 6 Pro";font-weight: 900;position:absolute;left:0;top:4px;font-size:12px;} */

.cms_pages ol > li:before{content:counters(item, ".") ". ";display:table-cell;padding-right:0.6em}
.cms_pages li ol > li{margin:0}
.cms_pages li ol > li:before{content:counters(item, ".") " "}
.cms_pages ol{list-style-type:none;counter-reset:item;margin:0;padding:0}
.cms_pages ol > li{display:table;counter-increment:item;margin-bottom:0.6em}


.h-452{height:452px}
.w-350{width:350px}
.h-381{height:381px}
.h-451{height:451px}
.max-h-300{max-height:300px}
.custom-feedback-box-shadow{box-shadow:0 2px 4px rgba(157,182,217,.2)}
.img-cont-box-shadow{box-shadow:16px 16px 30px 0 rgba(49,69,86,.08)}
.img-cont-vertical-box-shadow{border-radius:20px;box-shadow:22px 22px 40px 0 rgba(49,69,86,.1)}
.web-sol-box-img{box-shadow:22px 22px 40px rgba(49,69,86,.1)}
.min-wid-78{min-width:78px}
.line-h-46{line-height:46px}
.desktop-only,
.mordern-border-trans .animation-bar-2,
.mordern-border-trans .mordern-para{display:block}

.section1_bg{background:#FFF;padding:100px 0}
.section1_bg .ft-right-cont{align-items:end}
.fd-title{padding-top:100px;z-index:6;top:-70px;padding-bottom:40px;bottom:200px;line-height:46px!important;background:#FFF;color:#212529;font-size:30px;font-weight:600;text-align:center;border-bottom:solid 1px #f1f1f1}
.fd-title h2{font-size:30px;font-weight:600;color:#212529}
.ft-det-sticky-part{width:100%;z-index:4;padding:0 0 5px 0}
.ft-det-sticky-part h3{float:left;font-size:24px;color:#ef453f;line-height:50px;font-weight:600}
.ft-det-sticky-part h3 img{width:50px;float:left;margin:0 12px 0 0}
.feature-right-img-cont{height:100%;max-width:570px;display:flex;justify-content:center;align-items:center;overflow:hidden;border-radius:30px;background-color:#FFF;border:1px solid #eee}
.flex-box{display:flex;align-items:center;justify-content:space-between}
.mordern-title h4{line-height:28px;font-size:22px;color:#31314c;font-family:"Poppins-SemiBold";transition:all .2s ease-in-out}
.mordern-para p{line-height:28px;font-size:18px;color:#383838;font-weight:400;font-family:'Work Sans', sans-serif;margin:0;transition:all .2s ease-in-out}
.mordern-border-trans .mordern-title h4{color:#c21a01;transition:all .2s ease-in-out}
.mordern-border-trans .mordern-para p{color:#000;transition:all .2s ease-in-out}
.feature-det-cont .text_line{border-bottom:solid 2px #DDD;position:relative;padding:15px 0}


.feature-det-cont{width:100%;position:relative;opacity:1}
.ft-left-cont,
.ft-right-cont{height:100%;width:48%;display:flex;flex-direction:column}

@keyframes scroll{
0%{transform:translateX(0)}
100%{transform:translateX(calc(-250px * 7))}
}
.slider{margin:30px auto auto;overflow:hidden;position:relative;width:100%}
.slider::after{right:0;top:0}
.slider::before{left:0;top:0}
.slide-track{animation:42s linear infinite scroll;display:flex;width:auto}
.slide img{min-height:382px;max-height:382px;margin-bottom:50px;margin-top:50px;margin-right:40px;max-width:576px;object-fit:cover;display:flex;justify-content:flex-start;align-items:center}
.tm-tfi-bg-medium-gray{background:#e8f1ff}
.opacity-text-slider{opacity:.5}
.sm-1-carousel-slide-item{display:flex;flex-direction:column;width:100%;opacity:0;position:absolute;top:0;z-index:100;transition:transform .5s,opacity .5s,z-index .5s}
.feature-animate,
.feature-de-animate{display:block!important;animation-duration:2s;animation-delay:.5s;animation-fill-mode:forwards}
.mordern-learning-carousel-slide-item.active,
.mordern-learning-carousel-slide-item.initial,
.sm-1-carousel-slide-item.active,
.sm-1-carousel-slide-item.initial{opacity:1;position:relative;z-index:900}
.sm-1-carousel-slide-item.next,
.sm-1-carousel-slide-item.prev{z-index:800}
.sm-1-carousel-slide-item.next{transform:translateX(-100%)}
.sm-1-carousel-slide-item.prev{transform:translateX(100%)}
.carousel-wrapper{overflow:hidden;width:100%;margin:auto}
.carousel{-webkit-transform-style:preserve-3d;-moz-transform-style:preserve-3d;transform-style:preserve-3d}
.feedback-bg-gradient{background-image:linear-gradient(#fffaeb,rgb(255,250,235,0))}
.vertical-car{transition:transform 3s;transform:translate(0,-300px)}
.reverse-vertical-car{transition:transform 3s;transform:translate(0,0)}
.feature-animate{animation-name:animate-fade}
@keyframes animate-fade{
0%{opacity:0}
100%{opacity:1}
}
.feature-de-animate{animation-name:de-animate-fade}
@keyframes de-animate-fade{
0%{opacity:1}
100%{opacity:0}
}
.mordern-border-trans,
.mordern-border-trans-1,
.mordern-border-trans-2{transition:.1s ease-in}

.mordern-border-trans .mordern-title,
.mordern-border-trans-1 .mordern-title{color:#000;}
.mordern-border-trans .animation-bar-2 span{background-color:#ef453f}
.mordern-border-trans-1 .animation-bar-2 span{background-color:#ef453f}
.mordern-border-trans-2 .animation-bar-2 span{background-color:#ef453f}
.mordern-border-trans-3{border-left:3px solid #df8939;transition:.1s ease-in}
.sm-2-carousel-slide-item.prev,
.sm-3-carousel-slide-item.prev{transform:translateX(200%)}
.animation-bar-2{position:absolute;bottom:-10px;display:none;height:24px;width:100%}
.animation-bar-2 span{position:relative;display:inline-block;vertical-align:middle;height:3px;background-size:100%;animation:4s infinite progress-anim-2;background:#000}
.animation-bar-2 span:before{position:absolute;right:0;bottom:100%;display:inline-block;width:0;height:0;border:10px solid transparent;border-right-width:0;border-bottom-width:0;content:""}
.animation-bar-2 span:after{position:absolute;right:0;bottom:calc(100% + 5px);z-index:1;padding:5px;border-radius:3px;font-size:.8em;color:#fff}
@-webkit-keyframes progress-anim-2{
0%{width:0%}
100%{width:100%}
}

.mordern-title{padding:16px 0 16px 80px;}

.top_sticky_title{padding-top:6rem;width:calc(100% + 200px);margin-left:-100px;padding-left:100px;z-index:6;top:-70px;padding-bottom:70px;bottom:200px;line-height:46px!important}
.carousel img{width:100%;float:left}

.reg_biss_acc{float:left;width:100%;padding:100px 0}
.reg_biss_acc h2{float:left;width:100%;text-align:center;font-size:30px;color:#FFF;font-weight:600;padding-bottom:20px}
.reg_biss_acc a.btn{display:block;margin:0 auto;background:#ef453f;color:#FFF;max-width:180px;clear:both;-webkit-border-radius:100px;-moz-border-radius:100px;border-radius:100px;line-height:50px;padding:0;font-size:16px}

@media only screen and (max-width:991px){
.business_pagebanner .banner_detail{flex-flow:column}
.business_pagebanner h1{order:1;text-align:center}
.business_pagebanner img{order:2;max-width:100%}
.flex-box{flex-flow:column}
.ft-left-cont,
.ft-right-cont{width:100%}
.section1_bg .ft-right-cont,
.ft-right-cont{align-items:center}
.section1_bg,
.section2_bg{padding:50px 0}
.ft-left-cont{padding:30px 0}
.traing_solution_section{padding:50px 0}
}

@media only screen and (max-width:767px){
.business_pagebanner h1{font-size:30px;line-height:42px}
.traing_solution_section h2,
.fd-title h2,
.reg_biss_acc h2{font-size:22px}
.fd-title{padding-bottom:15px;padding-top:50px;top:-30px}
.ft-det-sticky-part h3{font-size:18px}
.marquee__content li{max-width:250px}
.ft-left-cont{order:2}
.ft-right-cont{order:1}
}


.edukula.accordion {
--bs-accordion-color: #212529;
--bs-accordion-bg: #f2f2f2;
--bs-accordion-transition: color 0.15s ease-in-out,background-color 0.15s ease-in-out,border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out,border-radius 0.15s ease;
--bs-accordion-border-color: var(--bs-border-color);
--bs-accordion-border-width: 1px;
--bs-accordion-border-radius: 12px;
--bs-accordion-inner-border-radius: calc(0.375rem - 1px);
--bs-accordion-btn-padding-x: 1.25rem;
--bs-accordion-btn-padding-y: 1rem;
--bs-accordion-btn-color: #212529;
--bs-accordion-btn-bg: var(--bs-accordion-bg);
--bs-accordion-btn-icon: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMCA1LjkyIj4KICA8cGF0aCBkPSJtOS43Mi4yOGMtLjM3LS4zNy0uOTctLjM3LTEuMzQsMGwtMy40MSwzLjM2TDEuNjIuMjhDMS4yNS0uMDkuNjUtLjA5LjI4LjI4Yy0uMTguMTgtLjI4LjQyLS4yOC42N3MuMS41LjI4LjY3bDQuMDIsNC4wMmMuMTguMTguNDIuMjguNjcuMjhzLjUtLjEuNjctLjI4TDkuNzIsMS42MmMuMTgtLjE4LjI4LS40Mi4yOC0uNjdzLS4xLS40OS0uMjgtLjY3aDBabTAsMCIvPgo8L3N2Zz4=");
--bs-accordion-btn-icon-width: 12px;
--bs-accordion-btn-icon-transform: rotate(-180deg);
--bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
--bs-accordion-btn-active-icon: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAyNy40LjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHZpZXdCb3g9IjAgMCAxMCA1LjkiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDEwIDUuOTsiIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPHN0eWxlIHR5cGU9InRleHQvY3NzIj4NCgkuc3Qwe2ZpbGw6I0MyMUEwMTt9DQo8L3N0eWxlPg0KPHBhdGggY2xhc3M9InN0MCIgZD0iTTkuNywwLjNjLTAuNC0wLjQtMS0wLjQtMS4zLDBMNSwzLjZMMS42LDAuM2MtMC40LTAuNC0xLTAuNC0xLjMsMEMwLjEsMC41LDAsMC43LDAsMXMwLjEsMC41LDAuMywwLjdsNCw0DQoJQzQuNSw1LjgsNC43LDUuOSw1LDUuOXMwLjUtMC4xLDAuNy0wLjNsNC4xLTRDOS45LDEuNCwxMCwxLjIsMTAsMC45UzkuOSwwLjUsOS43LDAuM0M5LjcsMC4zLDkuNywwLjMsOS43LDAuM3oiLz4NCjwvc3ZnPg0K");
--bs-accordion-btn-focus-border-color: #86b7fe;
--bs-accordion-btn-focus-box-shadow: 0 0 0 0.25rem rgba(13,110,253,0.25);
--bs-accordion-body-padding-x: 1.25rem;
--bs-accordion-body-padding-y: 1rem;
--bs-accordion-active-color: #0c63e4;
--bs-accordion-active-bg: #e7f1ff
}

.edukula .accordion-button{position:relative;display:flex;align-items:center;width:100%;padding:var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);font-size:1rem;color:var(--bs-accordion-btn-color);text-align:left;background-color:var(--bs-accordion-btn-bg);border:0;border-radius:0;overflow-anchor:none;transition:var(--bs-accordion-transition);font-family: "Poppins-SemiBold";color:#000}

@media (prefers-reduced-motion:reduce){
.edukula .accordion-button{transition:none}
}
.edukula .accordion-button:not(.collapsed){color:#c21a01;background-color:#f8f8f8;box-shadow:none}
.edukula .accordion-button:not(.collapsed):after{background-image:var(--bs-accordion-btn-active-icon);-webkit-transform:var(--bs-accordion-btn-icon-transform);transform:var(--bs-accordion-btn-icon-transform)}
.edukula .accordion-button:after{flex-shrink:0;width:var(--bs-accordion-btn-icon-width);height:var(--bs-accordion-btn-icon-width);margin-left:auto;content:"";background-image:var(--bs-accordion-btn-icon);background-repeat:no-repeat;background-size:var(--bs-accordion-btn-icon-width);transition:var(--bs-accordion-btn-icon-transition)}

@media (prefers-reduced-motion:reduce){
.edukula .accordion-button:after{transition:none}
}
.edukula .accordion-button:hover{z-index:2}
.edukula .accordion-button:focus{z-index:3;border-color:transparent;outline:0;box-shadow:0}
.edukula .accordion-header{margin-bottom:0}
.edukula .accordion-item{margin:0 0 10px;color:var(--bs-accordion-color);background-color:var(--bs-accordion-bg);border:var(--bs-accordion-border-width) solid var(--bs-accordion-border-color);overflow:hidden;border-radius:var(--bs-accordion-border-radius)}
.edukula .accordion-item:first-of-type{border-radius:var(--bs-accordion-border-radius)}
.edukula .accordion-item:first-of-type .accordion-button{border-radius:var(--bs-accordion-inner-border-radius)}
.edukula .accordion-item:not(:first-of-type){border-top:0}
.edukula .accordion-item:last-of-type{border-radius:var(--bs-accordion-border-radius)}
.edukula .accordion-item:last-of-type .accordion-button.collapsed{border-radius:var(--bs-accordion-inner-border-radius)}
.edukula .accordion-body{padding:var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x)}
.edukula .accordion-flush .accordion-collapse{border-width:0}
.edukula .accordion-flush .accordion-item{border-right:0;border-left:0;border-radius:0}
.edukula .accordion-flush .accordion-item:first-child{border-top:0}
.edukula .accordion-flush .accordion-item:last-child{border-bottom:0}
.edukula .accordion-flush .accordion-item .accordion-button,
.edukula .accordion-flush .accordion-item .accordion-button.collapsed{border-radius:0}
.edukula .accordion-collapse.collapse{visibility:hidden;display:none;}
.edukula .accordion-collapse.collapse.show{visibility:visible;display:block;background-color:#f8f8f8;}



.smartlxp.accordion {
--bs-accordion-color: #212529;
--bs-accordion-bg: #f2f2f2;
--bs-accordion-transition: color 0.15s ease-in-out,background-color 0.15s ease-in-out,border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out,border-radius 0.15s ease;
--bs-accordion-border-color: var(--bs-border-color);
--bs-accordion-border-width: 1px;
--bs-accordion-border-radius: 12px;
--bs-accordion-inner-border-radius: calc(0.375rem - 1px);
--bs-accordion-btn-padding-x: 1.25rem;
--bs-accordion-btn-padding-y: 1rem;
--bs-accordion-btn-color: #212529;
--bs-accordion-btn-bg: var(--bs-accordion-bg);
--bs-accordion-btn-icon: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMCA1LjkyIj4KICA8cGF0aCBkPSJtOS43Mi4yOGMtLjM3LS4zNy0uOTctLjM3LTEuMzQsMGwtMy40MSwzLjM2TDEuNjIuMjhDMS4yNS0uMDkuNjUtLjA5LjI4LjI4Yy0uMTguMTgtLjI4LjQyLS4yOC42N3MuMS41LjI4LjY3bDQuMDIsNC4wMmMuMTguMTguNDIuMjguNjcuMjhzLjUtLjEuNjctLjI4TDkuNzIsMS42MmMuMTgtLjE4LjI4LS40Mi4yOC0uNjdzLS4xLS40OS0uMjgtLjY3aDBabTAsMCIvPgo8L3N2Zz4=");
--bs-accordion-btn-icon-width: 12px;
--bs-accordion-btn-icon-transform: rotate(-180deg);
--bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
--bs-accordion-btn-active-icon: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAyNy40LjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHZpZXdCb3g9IjAgMCAxMCA1LjkiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDEwIDUuOTsiIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPHN0eWxlIHR5cGU9InRleHQvY3NzIj4NCgkuc3Qwe2ZpbGw6I0MyMUEwMTt9DQo8L3N0eWxlPg0KPHBhdGggY2xhc3M9InN0MCIgZD0iTTkuNywwLjNjLTAuNC0wLjQtMS0wLjQtMS4zLDBMNSwzLjZMMS42LDAuM2MtMC40LTAuNC0xLTAuNC0xLjMsMEMwLjEsMC41LDAsMC43LDAsMXMwLjEsMC41LDAuMywwLjdsNCw0DQoJQzQuNSw1LjgsNC43LDUuOSw1LDUuOXMwLjUtMC4xLDAuNy0wLjNsNC4xLTRDOS45LDEuNCwxMCwxLjIsMTAsMC45UzkuOSwwLjUsOS43LDAuM0M5LjcsMC4zLDkuNywwLjMsOS43LDAuM3oiLz4NCjwvc3ZnPg0K");
--bs-accordion-btn-focus-border-color: #86b7fe;
--bs-accordion-btn-focus-box-shadow: 0 0 0 0.25rem rgba(13,110,253,0.25);
--bs-accordion-body-padding-x: 1.25rem;
--bs-accordion-body-padding-y: 1rem;
--bs-accordion-active-color: #0c63e4;
--bs-accordion-active-bg: #e7f1ff
}

.smartlxp .accordion-button{position:relative;display:flex;align-items:center;width:100%;padding:var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);font-size:1rem;color:var(--bs-accordion-btn-color);text-align:left;background-color:var(--bs-accordion-btn-bg);border:0;border-radius:0;overflow-anchor:none;transition:var(--bs-accordion-transition);font-family: "Poppins-SemiBold";color:#000}

@media (prefers-reduced-motion:reduce){
.smartlxp .accordion-button{transition:none}
}
.smartlxp .accordion-button:not(.collapsed){color:#c21a01;background-color:#FFF;box-shadow:none}
.smartlxp .accordion-button:not(.collapsed):after{background-image:var(--bs-accordion-btn-active-icon);-webkit-transform:var(--bs-accordion-btn-icon-transform);transform:var(--bs-accordion-btn-icon-transform)}
.smartlxp .accordion-button:after{flex-shrink:0;width:var(--bs-accordion-btn-icon-width);height:var(--bs-accordion-btn-icon-width);margin-left:auto;content:"";background-image:var(--bs-accordion-btn-icon);background-repeat:no-repeat;background-size:var(--bs-accordion-btn-icon-width);transition:var(--bs-accordion-btn-icon-transition)}

@media (prefers-reduced-motion:reduce){
.smartlxp .accordion-button:after{transition:none}
}
.smartlxp .accordion-button:hover{z-index:2}
.smartlxp .accordion-button:focus{z-index:3;border-color:transparent;outline:0;box-shadow:0}
.smartlxp .accordion-header{margin-bottom:0}
.smartlxp .accordion-item{margin:0 0 10px;color:var(--bs-accordion-color);background-color:var(--bs-accordion-bg);border:var(--bs-accordion-border-width) solid var(--bs-accordion-border-color);overflow:hidden;border-radius:var(--bs-accordion-border-radius)}
.smartlxp .accordion-item:first-of-type{border-radius:var(--bs-accordion-border-radius)}
.smartlxp .accordion-item:first-of-type .accordion-button{border-radius:var(--bs-accordion-inner-border-radius)}
.smartlxp .accordion-item:not(:first-of-type){border-top:0}
.smartlxp .accordion-item:last-of-type{border-radius:var(--bs-accordion-border-radius)}
.smartlxp .accordion-item:last-of-type .accordion-button.collapsed{border-radius:var(--bs-accordion-inner-border-radius)}
.smartlxp .accordion-body{padding:var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x)}
.smartlxp .accordion-flush .accordion-collapse{border-width:0}
.smartlxp .accordion-flush .accordion-item{border-right:0;border-left:0;border-radius:0}
.smartlxp .accordion-flush .accordion-item:first-child{border-top:0}
.smartlxp .accordion-flush .accordion-item:last-child{border-bottom:0}
.smartlxp .accordion-flush .accordion-item .accordion-button,
.smartlxp .accordion-flush .accordion-item .accordion-button.collapsed{border-radius:0}
.smartlxp .accordion-collapse.collapse{visibility:hidden;display:none;}
.smartlxp .accordion-collapse.collapse.show{visibility:visible;display:block;background-color:#FFF;}